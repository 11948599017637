import onReady from './onReady';

/**
 * Get a promise that resolves when a global namespace becomes available (defined).
 * Times out if not found after specified timeout.
 * @param {string|string[]} globalName - name or array of names of library(s)
 * @param {number} [timeout=30000] - milliseconds
 * @returns {Promise<*>} A promise resolving to the library / namespace object.
 *
 * @example
 * window.ensureLib('$').then(function withJquery($) {
 *     $('#input').val('hello, world');
 * });
 *
 * window.ensureLib(['RewardsController', 'PasswordResetController'], 1000)
 *     .then(([RewardsController, PasswordResetController]) => {
 *         RewardsController.init(...);
 *         PasswordResetController.init(...);
 *     })
 *     .catch((e) => {
 *         console.error(e);
 *     });
 */
export default function ensureLib(globalName, timeout = 30000) {
    if (Array.isArray(globalName)) return Promise.all(globalName.map((n) => ensureLib(n, timeout)));
    return window[globalName] != null
        ? Promise.resolve(window[globalName])
        : new Promise((res, rej) => {
              onReady(() => {
                  if (window[globalName] == null) {
                      const start = Date.now();
                      const handler = setInterval(() => {
                          if (window[globalName] != null) {
                              clearInterval(handler);
                              res(window[globalName]);
                          } else if (Date.now() > start + timeout) {
                              clearInterval(handler);
                              rej(new Error(`Timed out after ${timeout}ms waiting for library: ${globalName}`));
                          }
                      }, 100);
                  } else {
                      res(window[globalName]);
                  }
              });
          });
}
