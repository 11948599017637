/**
 * This script is loaded in the HEAD of the document.
 * Accessing vendor externals that are loaded in the body,
 *  in this file or any dependencies will break this script.
 */
import onReady from 'Common/core/onReady';
import findApiFix from 'Common/core/findApiFix';
import ensureLib from 'Common/core/ensureLib';

window.ensureLib = ensureLib;
window.onReady = onReady;
findApiFix();
